import React from 'react';

const GetInTouch = () => (
  <div className="w-full mb-4 md:mb-0 md:w-1/2">
    <div className="h-full px-4 py-8 md:ml-0">
      <div className="w-full max-w-sm mx-auto text-xl leading-normal">
        <h2 className="md:text-3xl">Get in touch</h2>

        <p>
          Please call or email, or contact us through the website if you have
          any questions, we'd love to hear from you!
        </p>

        <p>We will get back to you as soon as possible.</p>

        <h3 className="block m-0 text-xs font-bold tracking-wide uppercase font-display">
          Phone:
        </h3>
        <p>
          <a href="tel:0412522849">0412&nbsp;522&nbsp;849</a>
        </p>

        <h3 className="block m-0 text-xs font-bold tracking-wide uppercase font-display">
          Email:
        </h3>
        <p>
          <a href="mailto:mryals@aspiringminds.com.au">
            mryals@aspiringminds.com.au
          </a>
        </p>

        <h3 className="block m-0 text-xs font-bold tracking-wide uppercase font-display">
          Address:
        </h3>
        <p>
          <a href="https://goo.gl/maps/ANRehgRZTJkkVgvL6">
            <span>98 Lake Road</span>
            <br />
            <span>Port Macquarie, 2444</span>
          </a>
        </p>

        <h3 className="block mb-2 text-xs font-bold tracking-wide uppercase font-display">
          Hours:
        </h3>
        <table>
          <tbody>
            <tr>
              <td>Monday&nbsp;&ndash;&nbsp;Friday</td>
              <td>3:30pm&nbsp;&ndash;&nbsp;6:30pm</td>
            </tr>
            <tr>
              <td>Weekends</td>
              <td>9:00am&nbsp;&ndash;&nbsp;5:00pm</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default GetInTouch;
