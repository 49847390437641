import React from 'react';
import LazyLoad from 'react-lazy-load';

const Map = () => (
  <div className="w-full p-4">
    <LazyLoad height={400} once>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.963063909812!2d152.90596881514878!3d-31.442683781395317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9dff3a03681e9d%3A0x8a5281348428e8cc!2s98%20Lake%20Rd%2C%20Port%20Macquarie%20NSW%202444!5e0!3m2!1sen!2sau!4v1629940383953!5m2!1sen!2sau"
        allowFullScreen
        className="w-full"
        frameBorder={0}
        height={400}
        style={{ border: 0 }}
        title="location"
      />
    </LazyLoad>
  </div>
);

export default Map;
